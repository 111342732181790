@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "director";
  src: url("./styles/Director-Regular.woff") format('woff2'),
       url("./styles/Director-Regular.woff") format('woff');
}

:root {
  font-family: "director" !important;
}

body {
  background-image: url("./img/download.jpg");
  background-size: cover;
  text-shadow: 0px 0px 5px #fff;
  overflow-x: hidden;
  background-color: #5B87A5;
}

.marq {
  background-image: url("./img/url.jpg");
  background-size: cover;
  background-position: center center;
}

.product-item > a > img {
  height: 50vh;
}

img {
  filter: brightness(1);
  transition: filter 1s;
}

img:hover:not(.pswp__img) {
  filter: brightness(1.5);
}